import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import {
  updateActiveFlag,
  saveUserProfile,
  fetchCandidateCredentials,
} from "src/redux/onBoarding/action";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import BoltIcon from "@mui/icons-material/Bolt";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
  CERTIFICATIONS_LICENSES,
  REFERENCES_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
} from "src/router/routes";
import "src/components/common/QuickApply/QuickApplyCard.scss";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { JOBS_DETAILS as JOB_DETAILS_SOURCE } from "src/constants/campaigns_constants";
import useFooterMargin from "src/hooks/useFooterMargin";
import Analytics from "src/services/Analytics";
import config from "src/config";
import SsnDobModal from "./SsnDob/SsnDobModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import EligibilityMeter from "../EligibilityMeter/EligibilityMeter";
import CredentialModal from "./CredentialModal/CredentialModal.component";

const enableSsnAndDobFields = config.ENABLE_SSN_AND_DOB_FIELDS === "true";
const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";

const QuickApplyCard = ({
  handleOnClick,
  isDisabled,
  isQuickAppliedSuccess,
  jobRequirements,
  job,
  updateTheActiveFlag,
  sourceUrl,
  myJobs,
  refreshQuickApplyCard,
  saveUserProfile,
  onBoarding,
  candidateId,
  isApplying,
  setIsApplying,
  fetchCredentials,
  companyId,
  atsBullhornCandidateId,
  candidateCredentials
}) => {
  // fetching details for credential GraphQL API in the Modal
  const credentialsApiObject = {
    companyId,
    atsBullhornCandidateId
  };

  const { t } = useTranslation();
  const quickApplyResponse = jobRequirements;
  const verifyReferences = () => {
    let isDefault = true;
    let title = "1+ Reference(s)";
    let subtext = "From a job worked within the last 12 months";
    if (!quickApplyResponse?.qualifications?.reference?.quickApply) {
      const pendingReferences = quickApplyResponse?.qualifications?.reference?.references?.filter(
        (list) => list.status === "Pending" || list.status === "In Progress"
      )?.length;
      if (pendingReferences > 0) {
        title = `${pendingReferences} Pending References`;
        subtext = "Your references still need to submit their responses";
        isDefault = false;
      }
    }
    return [isDefault, title, subtext];
  };

  const defaultImageUrl = config.JOB_CARD_IMAGE_URI_DOMAIN + config.JOB_CARD_DEFAULT_IMAGE;
  const imageUrl = job?.imageUri
    ? config.JOB_CARD_IMAGE_URI_DOMAIN + job.imageUri
    : defaultImageUrl;
  const [isDefaultReference, referenceTitle, referenceSubtext] = verifyReferences();
  const [toasterState, setToasterState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = toasterState;
  const toasterClose = () => {
    setToasterState({ ...toasterState, open: false });
  };
  const toasterSuccessIcon = (
    <div className="itemIcon completeIcon">
      <CheckCircleIcon />
    </div>
  );

  const trackSegmentEvent = (requirementType, num) => {
    updateTheActiveFlag(num);
    const segmentEventName = t("segment.missingJobRequirements");
    const segmentEventProps = {
      jobId: job.atsJobId,
      origin: localStorage.getItem("previousPageUrl") || "",
      profession: job.professionCode,
      specialty: job.specialtyCode,
      state: job.state,
      city: job.city,
      zip: job.zip,
      shift: job.shift,
      type: job.type,
      weeklyPay: job.salaryMax,
      facilityName: job.facilityName,
      sourceType: JOB_DETAILS_SOURCE,
      sourceSubArea: JOB_DETAILS_SOURCE,
      requirementType: requirementType,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  useEffect(() => {
    if (isQuickAppliedSuccess) {
      setToasterState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
      setIsApplying(false);
    }
  }, [isQuickAppliedSuccess]);

  const isApplied =
    myJobs &&
    (myJobs.quickApply._embedded.results.find((el) => job.id === el.id) ||
      myJobs.assignments._embedded.results.find((el) => job.id === el.id));
  const hasQuickApplied = isQuickAppliedSuccess || isApplied;
  const hasSsnAndDob = quickApplyResponse?.qualifications?.ssnAndDob?.quickApply ?? false;
  const canQuickApply = !hasQuickApplied && jobRequirements.quickApply;
  const appliedWithSSNAndDOB = hasQuickApplied ? hasSsnAndDob : true
  const { isSSNAvailable, socialSecurityNumber, dateOfBirth } = onBoarding?.basicInfo;

  const [isSsnDobModalOpen, setIsSsnDobModalOpen] = useState(false);
  const [isCredentialModalOpen, setIsCredentialModalOpen] = useState(false);

  useEffect(()=>{
    if(canQuickApply){
      const segmentName = t("segment.quickApplyQualified")
      const segmentEventProps = {
        segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      }
      Analytics.segmentTrackEvent(segmentName, segmentEventProps);
    }
  },[canQuickApply])

  const openSsnDobModal = () => {
    setIsSsnDobModalOpen(true);
  }

  const handleSsnDobModalSubmit = async (ssn, dob, noSsn) => {
    const { onboardingInformation } = onBoarding;

    await saveUserProfile(
      {
        event: "BASIC_INFO_SAVE",
        candidateId: candidateId,
        email: onboardingInformation.email,
        companyId: onboardingInformation.companyId,
        sqsEvent: true,
        profile: {
          meta: onboardingInformation.onboarding.meta,
          basicInfo: {
            ...onboardingInformation.onboarding.basicInfo,
            socialSecurityNumber:ssn,
            isSSNAvailable:noSsn,
            dateOfBirth: dob
          }
        }
      },
      onBoarding.onboardingInformation.onboarding.references,
      onBoarding.onboardingInformation.onboarding.workExperience
    )
      .then(refreshQuickApplyCard)
      .catch(error => {
        console.error(error);
      });

    refreshQuickApplyCard();
  }

  const handleSsnDobModalClose = () => {
    setIsSsnDobModalOpen(false);
  }

  const handleCredentialModalOpen = () => {
    trackSegmentEvent("State License", 5);
    setIsCredentialModalOpen(true);
  };

  useFooterMargin();

  return (
    <div className="quickApplyCardWrapper">
      <div className="quickApplyCardHead">
        {enableEligibilityMeter && !hasQuickApplied ? (
          <div className="quickApplyHeadingWrapper">
            <h2>{t("QuickApplyCard.QuickApplyHeading")}</h2>
            <EligibilityMeter
              eligibilityPercentage={quickApplyResponse?.eligibilityPercentage}
            />
          </div>
          ) :
          <h2>{t("QuickApplyCard.QuickApplyHeading")}</h2>
          }
        <div className="applicationTip">
          <div className="applicationTipHead">
            <span className="applicationTipIcon">
              <LightbulbOutlinedIcon />
            </span>
            {t("QuickApplyCard.QuickApplyApplicationTipHeading")}
          </div>
          {quickApplyResponse?.quickApply ? (
            <p className="applicationTipContent">
              {t("QuickApplyCard.QuickApplyApplicationTipContentComplete")}
              <b> {t("QuickApplyCard.QuickApplyApplicationTipHighlightedContent")}! </b>
            </p>
          ) : (
            <p className="applicationTipContent">
              {t("QuickApplyCard.QuickApplyApplicationTipContent")}
              <b> {t("QuickApplyCard.QuickApplyApplicationTipHighlightedContent")} </b>
              {t("QuickApplyCard.QuickApplyApplicationTipSubContent")}
            </p>
          )}
        </div>
      </div>
      <div className="quickApplyCardBody">
        {enableSsnAndDobFields && appliedWithSSNAndDOB && (
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div className={hasSsnAndDob ? "itemIcon completeIcon" : "itemIcon incompleteIcon"}>
                {hasSsnAndDob ? (
                  <CheckCircleIcon />
                ) : (
                  <CancelIcon />
                )}
              </div>
              <div>
                <div className="itemHeading">Social Security Number & Date of Birth</div>

                <SsnDobModal
                  isSSNAvailable = {isSSNAvailable}
                  socialSecurityNumber={socialSecurityNumber}
                  dateOfBirth={dateOfBirth}
                  isOpen={isSsnDobModalOpen}
                  initialSsn={socialSecurityNumber ?? null}
                  initialNoSsn={isSSNAvailable}
                  initialDob={dateOfBirth ?? null}
                  onSubmit={handleSsnDobModalSubmit}
                  onClose={handleSsnDobModalClose}
                />
              </div>
            </div>

            {!hasSsnAndDob ? (
              <button
                className="quickApplyCardUpdateBtn"
                onClick={openSsnDobModal}
              >
                {t("QuickApplyCard.UpdateNowLink")}
              </button>
            ) : null}
          </div>
        )}
        <div className="quickApplyCardItem">
          <div className="quickApplyCardItemRow">
            <div
              className={
                quickApplyResponse?.qualifications?.workHistory?.quickApply
                  ? "itemIcon completeIcon"
                  : "itemIcon incompleteIcon"
              }
            >
              {quickApplyResponse?.qualifications?.workHistory?.quickApply ? (
                <CheckCircleIcon />
              ) : (
                <CancelIcon />
              )}
            </div>
            <div>
              <div className="itemHeading">
                1+ year(s) of{" "}
                {quickApplyResponse?.qualifications?.specialtyProfession?.specialty?.replace(
                  new RegExp("covid 19", "gi"),
                  ""
                )}{" "}
                Experience
              </div>
              <div className="itemSubHeading">12 months+ experience within last 24 months</div>
            </div>
          </div>
          {!quickApplyResponse?.qualifications?.workHistory?.quickApply ? (
            <Link
              to={{
                pathname: `${WORK_EXP_PAGE}`,
                state: { source: sourceUrl, isUpdateNowClicked: true, image: imageUrl },
              }}
              className="quickApplyCardUpdateBtn"
              onClick={() => trackSegmentEvent("Work History", 2)}
            >
              {t("QuickApplyCard.UpdateNowLink")}
            </Link>
          ) : null}
        </div>
        <div className="quickApplyCardItem">
          <div className="quickApplyCardItemRow">
            <div
              className={
                quickApplyResponse?.qualifications?.reference?.quickApply
                  ? "itemIcon completeIcon"
                  : isDefaultReference
                  ? "itemIcon incompleteIcon"
                  : "itemIcon pendingIcon"
              }
            >
              {quickApplyResponse?.qualifications?.reference?.quickApply ? (
                <CheckCircleIcon />
              ) : isDefaultReference ? (
                <CancelIcon />
              ) : (
                <PendingIcon />
              )}
            </div>
            <div>
              <div className="itemHeading">{referenceTitle}</div>
              <div className="itemSubHeading">{referenceSubtext}</div>
            </div>
          </div>
          {!quickApplyResponse?.qualifications?.reference?.quickApply ? (
            <Link
              to={{
                pathname: `${REFERENCES_PAGE}`,
                state: { source: sourceUrl, isUpdateNowClicked: true, image: imageUrl },
              }}
              className="quickApplyCardUpdateBtn"
              onClick={() => trackSegmentEvent("References", 3)}
            >
              {!isDefaultReference ? (
                <div>{t("QuickApplyCard.ViewNowLink")}</div>
              ) : (
                <div>{t("QuickApplyCard.UpdateNowLink")}</div>
              )}
            </Link>
          ) : null}
        </div>
        {quickApplyResponse?.qualifications?.credential?.credentials &&
        quickApplyResponse?.qualifications?.credential?.credentials.length > 0 ? (
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div
                className={
                  quickApplyResponse?.qualifications?.credential?.quickApply
                    ? "itemIcon completeIcon"
                    : "itemIcon incompleteIcon"
                }
              >
                {quickApplyResponse?.qualifications?.credential?.quickApply ? (
                  <CheckCircleIcon />
                ) : (
                  <CancelIcon />
                )}
              </div>
              <div>
                <div className="itemHeading">
                  {quickApplyResponse?.qualifications?.credential?.credentials?.length} License
                  Verification(s)
                </div>
                {enableEligibilityMeter && (
                  <CredentialModal
                    isCredentialModalOpen={isCredentialModalOpen}
                    setIsCredentialModalOpen={setIsCredentialModalOpen}
                    quickApplyResponseCredentials={
                      quickApplyResponse?.qualifications?.credential?.credentials
                    }
                    fetchCredentials={fetchCredentials}
                    setToasterState={setToasterState}
                    credentialsApiObject={credentialsApiObject}
                    refreshQuickApplyCard={refreshQuickApplyCard}
                    candidateCredentials={candidateCredentials}
                  />
                )}
                <div className="itemSubHeading">
                  {quickApplyResponse?.qualifications?.credential?.credentials?.map((list) => (
                    <div key={`credential-${list.id}`}>
                      <span
                        className={
                          list.status === "Complete"
                            ? "completeOutlinedIcon"
                            : "incompleteOutlinedIcon"
                        }
                      >
                        {list.status === "Complete" ? <CheckIcon /> : <CloseIcon />}
                      </span>
                      <span>{list.name}</span>
                      {list.compact ? (
                        <span className="itemSubHeadingLabel">Compact Accepted *</span>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {!quickApplyResponse?.qualifications?.credential?.quickApply ? (
              enableEligibilityMeter ? (
                <button className="quickApplyCardUpdateBtn" onClick={handleCredentialModalOpen}>
                  {t("QuickApplyCard.UpdateNowLink")}
                </button>
              ) : (
                <Link
                  to={{
                    pathname: `${CERTIFICATIONS_LICENSES}`,
                    state: { source: sourceUrl, isUpdateNowClicked: true, image: imageUrl },
                  }}
                  className="quickApplyCardUpdateBtn"
                  onClick={() => trackSegmentEvent("State License", 5)}
                >
                  {t("QuickApplyCard.UpdateNowLink")}
                </Link>
              )
            ) : null}
          </div>
        ) : null}
        <div className="quickApplyCardItem">
          <div className="quickApplyCardItemRow">
            <div
              className={
                quickApplyResponse?.qualifications?.checkList?.quickApply
                  ? "itemIcon completeIcon"
                  : "itemIcon incompleteIcon"
              }
            >
              {quickApplyResponse?.qualifications?.checkList?.quickApply ? (
                <CheckCircleIcon />
              ) : (
                <CancelIcon />
              )}
            </div>
            <div>
              <div className="itemHeading">
                {`${quickApplyResponse?.qualifications?.checkList?.checkLists?.length
                    ? quickApplyResponse?.qualifications?.checkList?.checkLists?.length
                    : 1
                  } ${quickApplyResponse?.qualifications?.specialtyProfession?.specialty
                    ? quickApplyResponse?.qualifications?.specialtyProfession?.specialty?.replace(
                        new RegExp("covid 19", "gi"),
                        ""
                      )
                    : ""
                }`}{" "}
                Skills Checklist(s)
              </div>
              <div className="itemSubHeading">
                {quickApplyResponse?.qualifications?.checkList?.checkLists?.map((list) => (
                  <div key={`checklist-${list.id}`}>
                    <span
                      className={
                        list.status === "Complete"
                          ? "completeOutlinedIcon"
                          : "incompleteOutlinedIcon"
                      }
                    >
                      {list.status === "Complete" ? <CheckIcon /> : <CloseIcon />}
                    </span>
                    <span>{list.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!quickApplyResponse?.qualifications?.checkList?.quickApply ? (
            <Link
              to={{
                pathname: `${SKILL_CHECKLIST_OVERVIEW_PAGE}`,
                state: { source: sourceUrl, isUpdateNowClicked: true, image: imageUrl },
              }}
              className="quickApplyCardUpdateBtn"
              onClick={() => trackSegmentEvent("Skills Checklist", 4)}
            >
              {t("QuickApplyCard.UpdateNowLink")}
            </Link>
          ) : null}
        </div>
      </div>
      <div className={`quickApplyCardFooter ${!isApplied ? "notApplied" : ""}`}>
        <div className="toasterWrapper">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={toasterClose}
            autoHideDuration={3000}
            message={t("QuickApplyCard.QuickApplyApplicationToasterMessage")}
            key={vertical + horizontal}
            action={toasterSuccessIcon}
          />
        </div>
        <button
          onClick={handleOnClick}
          disabled={!canQuickApply || isApplying}
          className={hasQuickApplied ? "quickApplyButton quickApplied" : "quickApplyButton"}
        >
          <span>
            {isApplying && !hasQuickApplied ? (
              <CircularProgress size={17} className="quickApplyButtonIcon" />
            ) : hasQuickApplied ? (
              <CheckIcon className="quickApplyButtonIcon" />
            ) : (
              <BoltIcon className="quickApplyButtonIcon" />
            )}
            <span>
              {hasQuickApplied
                ? t("QuickApplyCard.QuickAppliedButton")
                : t("QuickApplyCard.QuickApplyButton")}
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  myJobs: state.myJobs.filteredJobs,
  onBoarding: state.onBoarding,
  candidateId: state.auth.candidateId,
  companyId: state.company.company.id,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  candidateCredentials: state.onBoarding.candidateCredentials
});
const mapDispatchToProps = (dispatch) => ({
  updateTheActiveFlag: (activeFlag) =>
    dispatch(updateActiveFlag(activeFlag)),
  saveUserProfile: (reqObj, referenceObj, workExperienceObj) =>
    dispatch(saveUserProfile(reqObj, referenceObj, workExperienceObj)),
  fetchCredentials: (bhId, companyId, authToken) =>
    dispatch(fetchCandidateCredentials(bhId, companyId, authToken)),
});

export { QuickApplyCard };
export default connect(mapStateToProps, mapDispatchToProps)(QuickApplyCard);
