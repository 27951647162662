import { withStyles } from "@material-ui/core/styles";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import "./multiSelect.scss";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { withTranslation } from "react-i18next";
import jobsSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import config from "src/config";
import Paper from "@mui/material/Paper";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { styled } from '@mui/material/styles';

const enableSpecialtiesDropdownV2 = config.ENABLE_SPECIALTIES_DROPDOWN_V2 === "true";

interface renderInputProps {
  t?: (value: string) => string;
  classes: { chip: string; chipContainer: string; paper: string };
  availableItems?: { name: string }[];
  defaultPlaceholderText?: string;
  maxLengthPlaceholderText?: string;
  noOptionSelectPlaceholderText?: string;
  onRemoveItem?: (value: string) => string;
  onInputValueChange: (name: string) => void;
  handleDelete: (code: string) => void;
  specialties?: [string, string][];
  handleDeleteAll: () => void;
  loading?: boolean;
  specialtySetter: (input: any) => void;
  inputValue?: string;
  disabled?: boolean;
  noSpecialtyResultText?: string;
  noSpecialtySelectedText?: string;
  enableDashboardAd:boolean
}

const styles = () => ({
  chipContainer: {
    backgroundColor: "transparent",
    display: "inline-block",
    marginBottom: 10,
  },
  chip: {
    marginTop: 20,
    marginRight: 5,
  },
  paper: {
    maxHeight: "auto",
    overflow: "hidden",
  },
});

const localStyles = makeStyles(() => ({
  dropdownContainer: {
    border: '2px solid white',
    borderRadius: '12px',
  },
  specialtyDropdownPaperClass: () => {
    const isExtraSmall = window.innerWidth < 600; 
    const isSmall = window.innerWidth >= 600 && window.innerWidth < 900; 
    
    return {
      borderRadius: isMobile ? "6px !important": "12px !important",
      margin: 'auto',
      marginTop: isExtraSmall || isSmall?"20.5px":"12px",
      position: 'fixed',
      left: isSmall?"-3%":"-5%",
      width:  isExtraSmall?"110%":"105%"

    };
  },
  dropdownShadow: {
    boxShadow: "0px 1px 20px 0px rgba(118, 118, 118, 0.25)",
    borderRadius: isMobile ? '6px' : '12px',

  },
  resultsColumn: {
    height: isMobile? '190px' : '280px', overflow: 'auto'
  },
  selectedColumn: {
    height: isMobile? '147px' : '280px',
    overflow: 'auto',
  },
  resultHeading: {
    fontSize: "16px",
    color: "#000000",
    margin: "0px",
    padding: "0px",
    fontWeight: 400
  },
  subHeading: {
    fontSize: "16px",
    margin: "0px",
    color: "#656E7E",
    padding: "6px 2px",
    borderBottom: "0.5px solid #EFEFEF",
    width: '100%'
  },
  noOptions: {
    fontSize: '14px',
    lineHeight: '17px',
    color: '#989898',
    overflowY: 'hidden',
    position: 'absolute',
    top: '50%'
  },
  paddingEight: {
    padding: '8px !important'
  },
  selectedContainer: {
    borderRadius: isMobile ? "4px" : "12px",
    backgroundColor: "#F5F5F5"
  },
  selectedHeadingSpan: {
    fontSize: "16px",
    color: "#000000",
    fontWeight: 400
  },
  subHeadingSelected: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#989898"
  },
  paddedTextField: {
    '& input::placeholder': {
      marginTo: "5px",
      fontSize: "16px",
      lineHeight: "25px"
    },
  }
}))

function MultiSelect({
  classes,
  availableItems,
  defaultPlaceholderText,
  noOptionSelectPlaceholderText,
  onInputValueChange,
  handleDelete,
  specialties,
  handleDeleteAll,
  loading,
  specialtySetter,
  inputValue,
  disabled,
  noSpecialtyResultText,
  noSpecialtySelectedText,
  enableDashboardAd
}: renderInputProps) {
  const localStyleClasses = localStyles({ classes,
    availableItems,
    defaultPlaceholderText,
    noOptionSelectPlaceholderText,
    onInputValueChange,
    handleDelete,
    specialties,
    handleDeleteAll,
    loading,
    specialtySetter,
    inputValue,
    disabled,
    noSpecialtyResultText,
    noSpecialtySelectedText,
    enableDashboardAd });

  const renderInput = (input) => {
    const {
      inputProps,
      selectedItemNew,
      defaultPlaceholderText,
      noOptionSelectPlaceholderText,
      handleChange,
    } = input;

    const placeHolderText =
      selectedItemNew && selectedItemNew.length === 0
        ? noOptionSelectPlaceholderText
        : defaultPlaceholderText;

    return (
      <div
        onClick={() => handleTextFieldClick()}
      >
        <TextField
          fullWidth
          placeholder={placeHolderText}
          className={`${selectedItemNew && selectedItemNew.length === 0 ? "errorMsg" : "p-0"} ${localStyleClasses.paddedTextField}`}
          onFocus={(event) => {
            setIsFocused(true);
            event.target.placeholder = placeHolderText;
          }}
          onKeyDown={() => handleTextFieldClick()}
          onBlur={(event) => {
            setIsFocused(false);
            event.target.placeholder =
              selectedItemNew && selectedItemNew.length === 0 ? noOptionSelectPlaceholderText : "";
          }}
          onChange={(event) => handleChange(event, { name: event.target.value })}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon fontSize="small" className="txtFieldClsIcon" />
              </InputAdornment>
            ),
          }}
          {...inputProps}
        />
      </div>
    );
  };


  const handleTextFieldClick = () => {

    if (!isMobile) return;

    const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement;
    activeElement.inputMode = 'text';

    document.getElementById("jobSearchMainV2")!.scrollIntoView();
  };

  const selectedItemNew =
    jobsSearchQueryBuilder.getArrayOfDescriptionAndCodesFromSpecialties(specialties);

  const availableOptionsRelatedToSearchTerm =
    jobsSearchQueryBuilder.getArrayOfSpecialtyCodeAndNameFromSpecialties(availableItems);

  const tagsLimit = isMobile ? 1 : 2;

  const handleChange = (event, value, reason) => {
    if (reason === "clear") {
      handleDeleteAll();
    }
    switch (event.type) {
      case "click":
        if (isMobile) {
          const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement;
          activeElement.inputMode = 'none';
          document.getElementById("jobSearchMainV2")!.scrollIntoView();
        }

        value.forEach((item) => {
          const specialtyArr = jobsSearchQueryBuilder.parseSpecialtyAndCode(
            `${item[0]} (${item[0]})`
          );

          return specialtySetter([specialtyArr[0], specialtyArr[0]]);
        });
        break;
      case "change":
        onInputValueChange(value.name);
        break;
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  const SpecialityDropdown = (props) => {

    return <div className={`container ${localStyleClasses.dropdownShadow}`} {...props.restProps}>
      <div className={`row ${localStyleClasses.dropdownContainer}`}>
        <div className={`col-md-5 ${localStyleClasses.paddingEight}`}>
          <div className={localStyleClasses.resultHeading} >Results</div>
          <div className={localStyleClasses.resultsColumn}>
          {
            Array.isArray(props.children) && props.children.length === 0 && inputValue === ""
            && <><p className={localStyleClasses.subHeading}> {noSpecialtyResultText} </p></>
          }
          {
            Array.isArray(props.children) && props.children.length === 0 && inputValue !== ""
            && <p className={` col-12 text-center ${localStyleClasses.noOptions}`}>No Options</p>
          }
          {
            Array.isArray(props.children) && props.children.length > 0
            && <div>
                {props.showResults && <div>{props.children}</div>}
              </div>
          }
          </div>
        </div>
        <div className={`col-md-7 ${localStyleClasses.selectedContainer} ${localStyleClasses.paddingEight}`}>
        <div>

            <div className="row">
              <div className="col-6">
                Selected
              </div>
              <div className="col-6 text-right">
                {specialties && specialties.length !== 0 && (
                  <button
                    type="button"
                    className="clearAll"
                    onClick={() => handleDeleteAll()}
                  >
                    Clear all
                  </button>
                )}
              </div>
            </div>
        </div>
          <div className={`specialtiesDropdownSelected ${localStyleClasses.selectedColumn}`}>
          {specialties?.length === 0 && <p className={localStyleClasses.subHeadingSelected} >{noSpecialtySelectedText}</p>}
          {(specialties && specialties.length > 0) &&
                specialties.sort((a, b) => a[0].localeCompare(b[0]))
                .map((option, index) => (
                  <Chip
                    className="specialtiesDropdownSelectedChip"
                    key={index}
                    size="small"
                    label={option[0]}
                    onDelete={() => {
                      handleDelete(option[0]);
                    }}
                    deleteIcon={<CloseIcon className="bg-dark rounded-circle fs-1" fontSize="small" />}
                  />
                ))
              }
          </div>
        </div>
      </div>
    </div>
  }

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  return (
    <div id="specialtyAutocomplete">
      {
        enableSpecialtiesDropdownV2 ?
          <Autocomplete
            open={isFocused}
            size="medium"
            freeSolo={inputValue === ""}
            className="listBox"
            disableClearable
            forcePopupIcon={false}
            multiple
            noOptionsText={<SpecialityDropdown showResults={false} children={[]} />}
            limitTags={tagsLimit}
            options={inputValue === "" || availableOptionsRelatedToSearchTerm.length === 0 ? [["DUMMY"]] : availableOptionsRelatedToSearchTerm}
            disableCloseOnSelect
            getOptionLabel={(option) => option[0]}
            onBlur={() => {
              onInputValueChange("");
            }}
            loading={loading}
            value={availableOptionsRelatedToSearchTerm ? specialties || [] : []}
            onChange={handleChange}
            renderTags={(availableItems) => {
              if (!isFocused)
                return availableItems.map(
                  (option) =>
                    option?.[0] && (
                      <Chip
                        style={{ margin: "3px" }}
                        variant="outlined"
                        label={option[0]}
                      />
                    )
                )
            }}
            renderOption={(props, option, { selected }) => {
              const isSelected = specialties!.some((item) => item[0] === option[0]) || selected

              return (
                <ListItem {...props}
                  style={{
                    justifyContent: "space-between",
                    lineHeight: "20px",
                    fontWeight: "400",
                    padding: "8px",
                    fontSize: '16px',
                    cursor: 'pointer',
                    borderBottom: '0.5px solid #EFEFEF',
                    width: isMobile ? '98%' : '100%'
                  }}
                >
                  <span className="specialtiesTextColorDark" style={{ fontWeight: 500, maxWidth: "85%" }}>
                    {option[0]}
                  </span>
                  {isSelected && <CheckIcon sx={{ margin: "0px", padding: "0px" }} fontSize="small" />}
                </ListItem>
              )
            }}

            renderInput={(params) =>
              renderInput({
                classes,
                selectedItemNew,
                availableItems,
                defaultPlaceholderText,
                noOptionSelectPlaceholderText,
                handleChange,
                inputProps: params,
              })
            }
            ListboxComponent={({ children, ...other }) => {
              if (Array.isArray(children)) {
                children = children.filter((option) => option.key !== "DUMMY")
              }
              const { className, ...propsExceptClassName } = other;

              return <SpecialityDropdown showResults={true} children={children} restProps={propsExceptClassName} />
            }}
            PaperComponent={({ children }) => (
              <Paper
              className={localStyleClasses.specialtyDropdownPaperClass}>
                {children}
              </Paper>
            )}
            PopperComponent={(props) => <StyledPopper {...props} className="zIndexSpecialtyDropdown" />}
          />
          : <Autocomplete
            freeSolo={inputValue === ""}
            className="listBox"
            multiple
            limitTags={1}
            options={inputValue === "" ? [] : availableOptionsRelatedToSearchTerm}
            disableCloseOnSelect
            getOptionLabel={(option) => option[0]}
            onBlur={() => {
              onInputValueChange("");
            }}
            loading={loading}
            value={availableOptionsRelatedToSearchTerm ? specialties : []}
            onChange={handleChange}
            renderTags={(availableItems, getTagProps) =>
              availableItems.map(
                (option, index) =>
                  option?.[0] && (
                    <Chip
                      variant="outlined"
                      label={option[0]}
                      deleteIcon={
                        <div
                          onMouseDown={() => {
                            if (!disabled) {
                              handleDelete(option[0]);
                            }
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </div>
                      }
                      {...getTagProps({ index })}
                    />
                  )
              )
            }
            renderOption={(props, option, { selected }) => (
              <li
                className="listBox"
                {...props}
                style={{ padding: 0, color: "#626C7D", border: 0 }}
                key={`${option[0]}(${option[0]})`}
              >
                <Checkbox
                  checked={specialties!.some((item) => item[0] === option[0]) || selected}
                  onChange={(event) => {
                    if (!event.target.checked) {
                      handleDelete(option[0]);
                    } else {
                      handleChange(event, option[0], "");
                    }
                  }}
                />
                {option[0]}
              </li>
            )}
            renderInput={(params) =>
              renderInput({
                classes,
                selectedItemNew,
                availableItems,
                defaultPlaceholderText,
                noOptionSelectPlaceholderText,
                handleChange,
                inputProps: params,
              })
            }
          />
      }
    </div>
  );
}

export default withStyles(styles)(withTranslation()(MultiSelect));
