/* eslint-disable import/no-cycle */
import { push } from "connected-react-router";
import API from "../../services/Api";
import JobCardContainer from "../../components/common/MainContent/JobResults/JobCardContainer";
import { JOBS_RESULTS } from "../../router/routes";
import {
  SIMILAR_JOBS,
  CROWD_SOURCING_JOBS,
  JOBS_DETAILS,
} from "../../constants/campaigns_constants";
import config from "../../config";

const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";

export const DETECT_JOB_CARD_CLICK = "DETECT_JOB_CARD_CLICK";
export const fetchJobDetail = (payload) => ({ type: DETECT_JOB_CARD_CLICK, payload });

export const FETCH_SIMILAR_JOBS_REQUEST = "FETCH_SIMILAR_JOBS_REQUEST";
export const fetchSimilarJobsRequest = (payload) => ({ type: FETCH_SIMILAR_JOBS_REQUEST, payload });

export const FETCH_SIMILAR_JOBS_SUCCESS = "FETCH_SIMILAR_JOBS_SUCCESS";
export const fetchSimilarJobsSuccess = (payload) => ({ type: FETCH_SIMILAR_JOBS_SUCCESS, payload });

export const FETCH_SIMILAR_JOBS_FAILURE = "FETCH_SIMILAR_JOBS_FAILURE";
export const fetchSimilarJobsFailure = (payload) => ({ type: FETCH_SIMILAR_JOBS_FAILURE, payload });

const enableExcludeJobs = config.ENABLE_EXCLUDE_JOBS === "true";

export const FETCH_COLLABORATED_JOBS_REQUEST = "FETCH_COLLABORATED_JOBS_REQUEST";
export const fetchCollaboratedJobsRequest = (payload) => ({
  type: FETCH_COLLABORATED_JOBS_REQUEST,
  payload,
});

export const FETCH_COLLABORATED_JOBS_SUCCESS = "FETCH_COLLABORATED_JOBS_SUCCESS";
export const fetchCollaboratedJobsSuccess = (payload) => ({
  type: FETCH_COLLABORATED_JOBS_SUCCESS,
  payload,
});

export const FETCH_COLLABORATED_JOBS_FAILURE = "FETCH_COLLABORATED_JOBS_FAILURE";
export const fetchCollaboratedJobsFailure = (payload) => ({
  type: FETCH_COLLABORATED_JOBS_FAILURE,
  payload,
});

export const UPDATE_SIMILAR_JOBS_CAROUSEL = "UPDATE_SIMILAR_JOBS_CAROUSEL";
export const updateSimilarJobsCarousel = (payload) => ({
  type: UPDATE_SIMILAR_JOBS_CAROUSEL,
  payload,
});

export const similarJobsCarousel = (items) => (dispatch) => {
  const cardArray = [];
  items.forEach((job) => {
    cardArray.push(
      <JobCardContainer
        job={job}
        similarOrViewed="SIMILAR"
        campaignCode={SIMILAR_JOBS}
        sourceType={JOBS_DETAILS}
      />
    );
  });
  dispatch(updateSimilarJobsCarousel(cardArray));
};

export const fetchSimilarJobs = (obj) => async (dispatch) => {
  const similarUrl = enableExcludeJobs ? "globalSimilar" : "similar";

  dispatch(fetchSimilarJobsRequest());

  try {
    let response;
    if (enableEligibilityMeter) {
      response = await API.post(`jobs/${obj.job.id}/${similarUrl}`,
        {
          candidateInformation: obj.candidateInformation
        },
        {
          params: {
            companyId: obj.companyId,
            size: obj.size,
            candidateId: obj.candidateId,
            includeQuickApplyEligibility: true
          }
        }
      );
    } else {
      response = await API.get(`jobs/${obj.job.id}/${similarUrl}`, {
        params: {
          companyId: obj.companyId,
          size: obj.size,
        },
      });
    }

    if (response.status === 200) {
      dispatch(fetchSimilarJobsSuccess(response.data));
      dispatch(similarJobsCarousel(response.data._embedded.results));
    } else {
      dispatch(fetchSimilarJobsFailure(response));
    }
  } catch (error) {
    dispatch(fetchSimilarJobsFailure(error));
  }
};


export const UPDATE_COLLABORATED_JOBS_CAROUSEL = "UPDATE_COLLABORATED_JOBS_CAROUSEL";
export const updateCollaboratedJobsCarousel = (payload) => ({
  type: UPDATE_COLLABORATED_JOBS_CAROUSEL,
  payload,
});

export const collaboratedJobsCarousel = (items) => (dispatch) => {
  const cardArray = [];
  items.forEach((job) => {
    cardArray.push(
      <JobCardContainer
        job={job}
        similarOrViewed="COLLABORATED"
        campaignCode={CROWD_SOURCING_JOBS}
        sourceType={JOBS_DETAILS}
      />
    );
  });
  dispatch(updateCollaboratedJobsCarousel(cardArray));
};

export const fetchCollaboratedJobs = (obj) => async (dispatch) => {
  const collaboratedUrl = enableExcludeJobs ? "peopleViewed" : "collaborated";

  dispatch(fetchCollaboratedJobsRequest());
  try {
    let response;
    if (enableEligibilityMeter) {
      response = await API.post(
        `jobs/${obj.job.id}/${collaboratedUrl}`,
        {
          candidateInformation: obj.candidateInformation,
        },
        {
          params: {
            companyId: obj.companyId,
            size: obj.size,
            candidateId: obj.candidateId,
            includeQuickApplyEligibility: true,
          },
        }
      );
    } else {
      response = await API.get(`jobs/${obj.job.id}/${collaboratedUrl}`, {
        params: {
          companyId: obj.companyId,
          size: obj.size,
        },
      });
    }

    if (response.status === 200) {
      dispatch(fetchCollaboratedJobsSuccess(response.data));
      dispatch(collaboratedJobsCarousel(response.data._embedded.results));
    } else {
      dispatch(fetchCollaboratedJobsFailure(response));
    }
  } catch (error) {
    dispatch(fetchCollaboratedJobsFailure(error));
  }
};

export const detectJobCardClick = (obj) => (dispatch) => {
  const selectedJob = {
    isCardClicked: obj.isCardClicked,
    job: obj.job,
    isRecommendedJobsOnResult: obj.isRecommended,
    campaignCode: obj.campaignCode,
    candidateId:obj.candidateId,
    candidateInformation:obj.candidateInformation,
  };
  if (obj.isCardClicked && obj.job) {
    dispatch(fetchSimilarJobs(obj));
    dispatch(fetchCollaboratedJobs(obj));
    dispatch(fetchJobDetail(selectedJob));
  } else {
    dispatch(push(JOBS_RESULTS));
  }
};

export const UPDATE_SIMILAR_JOB_OBJECT = "UPDATE_SIMILAR_JOB_OBJECT";
export const updateSimilarJobsObject = (payload) => ({
  type: UPDATE_SIMILAR_JOBS_CAROUSEL,
  payload,
});

export const UPDATE_COLLABORATED_JOB_OBJECT = "UPDATE_COLLABORATED_JOB_OBJECT";
export const updateCollaboratedJobsObject = (payload) => ({
  type: UPDATE_COLLABORATED_JOBS_CAROUSEL,
  payload,
});

export const updateCarouselJobObject = (payload) => (dispatch) => {
  const type = payload.similarOrViewed === "SIMILAR" ? "similarJobs" : "collaboratedJobs";
  const payloadUpdated = payload[String(type)].map((job) => {
    const jobUpdated = { ...job };
    if (payload.jobId === job.props.job.id) {
      switch (payload.action) {
        case "favorite":
          jobUpdated.props.job.isFavorite = payload.request.isActive;
          break;

        case "apply":
          jobUpdated.props.job.isApplied = payload.request.isActive;
          break;

        case "email":
          jobUpdated.props.job.isEmailed = payload.request.isActive;
          break;

        default:
          break;
      }
    }
    return jobUpdated;
  });

  if (type === "similarJobs") {
    dispatch(updateSimilarJobsObject(payloadUpdated));
  } else {
    dispatch(updateCollaboratedJobsObject(payloadUpdated));
  }
};

export const UPDATE_JOB_DETAILS = "UPDATE_JOB_DETAILS";
export const updateJobDetails = (payload) => {
  const payloadUpdated = { ...payload };
  if (payload.jobId === payload.jobDetails.id) {
    switch (payload.action) {
      case "favorite":
        payloadUpdated.jobDetails.isFavorite = payload.request.isActive;
        break;

      case "apply":
        payloadUpdated.jobDetails.isApplied = payload.request.isActive;
        break;

      case "email":
        payloadUpdated.jobDetails.isEmailed = payload.request.isActive;
        break;

      case "quickApply":
        payloadUpdated.jobDetails.isQuickApplied = payload.request.isActive;
        break;

      default:
        break;
    }
  }
  return { type: UPDATE_JOB_DETAILS, payloadUpdated };
};


export const SET_JOB_QUICK_APPLY_REQUIREMENTS = "SET_JOB_QUICK_APPLY_REQUIREMENTS";
export const setJobQuickApplyRequirements = (payload) => ({
  type: SET_JOB_QUICK_APPLY_REQUIREMENTS,
  payload,
});