import { jobDetailType } from "src/components/common/MainContent/JobResults/common/index";
import config from "src/config";

export interface EligibilityMeterRequirement {
  isMet: boolean;
}

const EligibilityPercentage = (job: jobDetailType) => {
  const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";
  const { isQuickApplied, quickApplyEligibility } = job;
  const showEligibilityMeter = enableEligibilityMeter && isQuickApplied !== true;
  const eligibilityPercentage = quickApplyEligibility?.eligibilityPercentage ?? 0;

  return showEligibilityMeter && eligibilityPercentage > 20 ? eligibilityPercentage : null;
};

export default EligibilityPercentage;
