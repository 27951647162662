import clsx from "clsx";
import { isMobile } from "react-device-detect";
import styles from "src/components/common/EligibilityMeter/EligibilityMeter.module.scss";
import QuickApply from "src/imagesV2/EligibilityMeter/quick-apply.svg";
import { connect } from "react-redux";

interface EligibilityMeterProps {
  showQuickApplyText?: boolean;
  eligibilityPercentage?: number
}

const EligibilityMeter = ({
  showQuickApplyText,
  eligibilityPercentage = 0,
}: EligibilityMeterProps) => {
  let eligibilityColor = {
    innerCircleColor: "#FDE3E4",
    barColor: "D7282F",
    barCircleColor: "#9A1B20",
    textColor: "#216D35",
  };

  if (eligibilityPercentage >= 20 && eligibilityPercentage <= 49) {
    eligibilityColor = {
      innerCircleColor: "#FDE3E4",
      barColor: "#D7282F",
      barCircleColor: "#9A1B20",
      textColor: "#7D1F23",
    };
  } else if (eligibilityPercentage >= 50 && eligibilityPercentage <= 79) {
    eligibilityColor = {
      innerCircleColor: "#FBDBC3",
      barColor: "#F67B35",
      barCircleColor: "#BF5E26",
      textColor: "#885110",
    };
  } else if (eligibilityPercentage >= 80 && eligibilityPercentage <= 99) {
    eligibilityColor = {
      innerCircleColor: "#FBF0C3",
      barColor: "#F6AA35",
      barCircleColor: "#C78724",
      textColor: "#9E6B1D",
    };
  } else if (eligibilityPercentage === 100) {
    eligibilityColor = {
      innerCircleColor: "rgba(50, 154, 78, 0.1)",
      barColor: "#329A4E",
      barCircleColor: "#26743B",
      textColor: "#216D35",
    };
  }

  function calculateRotation(percentage: number): number {
    const minRotation = isMobile ? -170 : -175; // Rotation for 0%
    const maxRotation = isMobile ? -10 : -5; // Rotation for 100%

    const rotation = minRotation + (percentage / 100) * (maxRotation - minRotation);

    return rotation;
  }

  if (!eligibilityPercentage || eligibilityPercentage <= 20) {
    return null;
  }

  return (
    <div className={clsx(!showQuickApplyText && styles.noQuickApplyText)}>
      <div className={styles.wrapper}>
        <div
          className={styles.outerCircle}
          style={{ backgroundColor: eligibilityColor.innerCircleColor }}
        >
          <div className={styles.barWrapper}>
            <div
              className={styles.bar}
              style={
                {
                  "--bar-border-color": `${eligibilityColor.barColor}`,
                  "--bar-circle-color": `${eligibilityColor.barCircleColor}`,
                  "--rotate-bar": `rotate(${calculateRotation(eligibilityPercentage)}deg)`,
                } as React.CSSProperties
              }
            />
          </div>
          <span
            className={styles.text}
            style={
              { "--text-color": `${eligibilityColor.textColor}` } as React.CSSProperties
            }
          >
            {eligibilityPercentage}%
          </span>
        </div>
      </div>

      {showQuickApplyText && (
        <div className={styles.quickApplyTextContainer}>
          <img src={QuickApply} alt="quick-apply-icon" />
          <span className={styles.quickApplyText}>QUICK APPLY</span>
        </div>
      )}
    </div>
  );
};

EligibilityMeter.defaultProps = {
  showQuickApplyText: false,
  eligibilityPercentage: undefined
};

const mapStateToProps = (state) => ({
  eligibilityPercentages: state.eligibilityPercentage,
});

export default connect(mapStateToProps)(EligibilityMeter);
