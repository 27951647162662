import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import checkImg from "src/imagesV2/checkCircle.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import styles from "./CertificationsLicenses.module.scss";

interface CertificationsLicensesUploadProps {
  uploaded: (File | null)[];
  setUploaded: (file: (File | null)[]) => void;
  twoSided: boolean;
  uploadedFilesIds: Number[] | null;
  setUploadedFileIdsCallBack: (id: Number[] | null) => void;
  setUploadedStatus: (rec: Record<Side, string | null>) => void;
  uploadedStatus: Record<Side, string | null>;
  isFileDownloading: boolean;
}
type Side = "front" | "back";
type UploadError = Record<Side, string | null>;

const sideToIndex = {
  front: 0,
  back: 1,
};

function isValidFileType(file: File) {
  const allowedTypes = {
      "image/jpeg": true,
      "image/jpg": true,
      "image/png": true,
      "image/gif": true,
      "image/heic": true,
      "image/heif": true,
      "image/tiff": true,
      "application/pdf": true,
      "application/msword": true,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": true,
      "application/vnd.oasis.opendocument.text": true,
      "application/rtf": true,
      "text/plain": true,
      "application/vnd.ms-excel": true,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
      "text/csv": true,
      "application/vnd.ms-powerpoint": true,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": true,
      "application/vnd.ms-outlook": true,
      "message/rfc822": true
  };

  if (allowedTypes[file.type]) {
      return true;
  }

  const fileName = file.name.toLowerCase();
  const validExtensions = [
      ".pdf", ".jpg", ".jpeg", ".png", ".heic", ".jfif",
      ".gif", ".heif", ".tif", ".tiff", ".docx", ".doc",
      ".odt", ".rtf", ".txt", ".xls", ".xlsx", ".csv",
      ".ppt", ".pptx", ".msg", ".eml"
  ];

  return validExtensions.some(ext => fileName.endsWith(ext));
}

const CertificationsLicensesUpload: FC<CertificationsLicensesUploadProps> = ({
  uploaded,
  setUploaded,
  twoSided,
  uploadedFilesIds,
  setUploadedFileIdsCallBack,
  setUploadedStatus,
  uploadedStatus,
  isFileDownloading,
}): ReactElement => {
  const { t } = useTranslation();

  const [error, setError] = useState<UploadError>({ front: null, back: null });

  const invalidFileTypeError = t("certificationAndLicenses.error.type");
  const isFrontInvalidFileType = error?.front === invalidFileTypeError && uploaded?.[0] == null;
  const isBackInvalidFileType = error?.back === invalidFileTypeError && uploaded?.[1] == null;

  const handleFileChange = (file: File | undefined, side: Side) => {
    if (file) {
      if (!isValidFileType(file)) {
        setError({ ...error, [side]: invalidFileTypeError });
        return;
      }

      if (file.size > 5000000) {
        setError({ ...error, [side]: t("certificationAndLicenses.error.size") });
        return;
      }

      const upload = [...uploaded];
      upload[sideToIndex[String(side)]] = file;
      setUploaded(upload);
      setError({ ...error, [side]: null });
    }
  }

  const handleChange = (event, name: Side) => {
    const newFile = event?.target.files[0];
    const blob = newFile.slice(0, newFile.size, newFile.type);
    const fileNameArray = newFile.name.split(".");
    const file = new File([blob], `${fileNameArray[0]}_${name}.${fileNameArray[1]}`, {
      type: blob.type,
    });

    handleFileChange(file, name);
  };

  const handleDrop = (event, name: Side) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    handleFileChange(file, name);
  };

  const renderTextOrError = (side: Side) => {
    const errorMessage = error[String(side)];
    if (errorMessage) {
      return (
        <p>
          <b>{errorMessage}</b>
          {t("certificationAndLicenses.tryAgain")}
        </p>
      );
    }

    return twoSided ? (
      <span> {t(`certificationAndLicenses.${side}`)}</span>
    ) : (
      <span>
        <u>{t("certificationAndLicenses.chooseAFile")}</u>
        {t("certificationAndLicenses.orDragItHere")}
      </span>
    );
  };

  const renderEmptyState = (side: Side) => {
    const classNames = clsx(
      "uploadDropBox",
      "fileUploadError",
      error[String(side)] && "uploadError",
      twoSided && side === "back" && "uploadBack",
      twoSided && side === "front" && "uploadFront"
    );
    return (
      <label htmlFor={`upload${side}`} key={side}>
        <div
          className={classNames}
          onDrop={(e) => handleDrop(e, side)}
          onDragOver={(e) => e.preventDefault()}
        >
          <input
            id={`upload${side}`}
            onChange={(e) => handleChange(e, side)}
            hidden
            accept=".pdf,.jpg,.png,.gif,.ppt,.pptx,.rtf,.txt,.xls,.xlsx,.csv,.doc,.docx"
            multiple
            type="file"
          />
          {renderTextOrError(side)}
        </div>
      </label>
    );
  };

  const handleDeleteFile = (side: Side) => {
    const updated = [...uploaded];
    updated[sideToIndex[String(side)]] = null;
    setUploaded(updated);

    if (uploadedFilesIds && uploadedFilesIds?.length > 0) {
      if (side === "front") {
        uploadedFilesIds.shift();
      } else {
        uploadedFilesIds.pop();
      }
    }

    setUploadedFileIdsCallBack(uploadedFilesIds);
    setUploadedStatus({ ...uploadedStatus, [side]: "" });
    setError({ ...error, [side]: null });
  };

  const replaceFileName = (fileName: string) => {
    return fileName?.toLowerCase().replace("_front", "").replace("_back", "");
  };

  const renderResult = (file: File, side: Side) => {
    const fileName = twoSided ? t(`certificationAndLicenses.${side}`) : replaceFileName(file.name);
    const fileSrc = file.type.includes("image") ? URL.createObjectURL(file) : checkImg;

    return (
      <div className="uploadResult" key={side}>
        <img alt="docImg" src={fileSrc} />
        <p>{fileName}</p>
        <div
          role="button"
          onClick={() => handleDeleteFile(side)}
          onKeyUp={() => handleDeleteFile(side)}
          tabIndex={0}
          className="uploadDelete"
        >
          {t("certificationAndLicenses.delete")}
        </div>
      </div>
    );
  };

  const renderInvalidFileType = (side: Side) => {
    const classNames = clsx(
      "invalidFileTypeDropBox",
      "fileUploadError",
      error[String(side)] && "uploadError",
      twoSided && side === "back" && "uploadBack",
      twoSided && side === "front" && "uploadFront"
    );
    return (
      <label htmlFor={`upload${side}`} key={side}>
        <div
          className={classNames}
          onDrop={(e) => handleDrop(e, side)}
          onDragOver={(e) => e.preventDefault()}
        >
          <ErrorIcon />
          <input
            id={`upload${side}`}
            onChange={(e) => handleChange(e, side)}
            hidden
            accept=".pdf,.jpg,.png,.gif,.ppt,.pptx,.rtf,.txt,.xls,.xlsx,.csv,.doc,.docx"
            multiple
            type="file"
          />
          <div className={styles.incorrectFileType}>{t("certificationAndLicenses.error.type")}</div>
          <div className={styles.pleaseTryAgain}>{t("certificationAndLicenses.tryAgain")}</div>
        </div>
    </label>
    );
  };

  const renderUpload = (el: File | null, side: Side) => {
    if (el instanceof File) {
      return renderResult(el, side);
    }
    return renderEmptyState(side);
  };

  return (
    <>
      {isFileDownloading && (
        <div className="uploadWrapper">
          <div className={twoSided ? "uploadBoxWrapper" : ""}>
            {twoSided && (
              <div className="loader">
                <CircularProgress size="3rem" />
              </div>
            )}
            <div className="loader">
              <CircularProgress size="3rem" />
            </div>
          </div>
        </div>
      )}
      {!isFileDownloading && (
        <div className="uploadWrapper">
          <div className={twoSided ? "uploadBoxWrapper" : ""}>
            {uploaded.map((el, index) => {
              const side = index === 0 ? "front" : "back";

              if (side === "front" && isFrontInvalidFileType) {
                return renderInvalidFileType("front");
              }

              if (side === "back" && isBackInvalidFileType) {
                return renderInvalidFileType("back");
              }

              return renderUpload(el, side);
            })}
          </div>
          {uploaded.includes(null) && (
            <>
              <div className="fileRequired">{t("onBoarding.errorMessage.required")}</div>
              <span className="uploadWarning">{t("certificationAndLicenses.fileLimitText")}</span>
              <span className="uploadWarning"> {t("certificationAndLicenses.FileTypesAccepted")}</span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CertificationsLicensesUpload;
