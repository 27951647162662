/* eslint-disable react/jsx-props-no-spreading */
import { jobDetailType, reqObjType } from "src/components/common/MainContent/JobResults/common";
import clsx from "clsx";
import config from "src/config";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import MailIcon from "@material-ui/icons/Mail";
import Analytics from "src/services/Analytics";
import eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage"
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { v4 as uuidv4 } from "uuid";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import { connect } from "react-redux";
import styles from "./JobCardHeader.module.scss";
import JobCardChip, { JobCardChipProps } from "../chip/JobCardChip";
import ControlledEmailTooltip from "../../../JobResults/ControlledEmailTooltip";

const imageUrl = config.JOB_CARD_IMAGE_URI_DOMAIN;

export interface JobCardHeaderProps {
  job?: jobDetailType;
  chips?: JobCardChipProps[];
  showContactAction?: boolean;
  showFavoriteAction?: boolean;
  allJobs: any;
  jobDetails: any;
  similarJobs: any;
  collaboratedJobs: any;
  myJobs: any;
  recommendedJobs: any;
  recommended?: boolean;
  isRecommendedJobsOnResult?: boolean;
  similarOrViewed?: string;
  viewMyJobs?: boolean;
  className?: string;
  error: boolean;
  emailSuccess: boolean;
  emailSuccessResponse: {
    appliedJobId: string;
    message: string;
    status: number;
    statusCode: string;
  };
  actionJobId: string;
  sourceType: string;
  campaignCode: string;
  postTheJobApplicationFavoriteEmail: (reqObj: reqObjType & { jobId: string }) => void;
  onEmailClick: () => void;
  onClick?: () => void;
}

const trackSegmentEvent = (eventName: string, props) => {
  const { job } = props;

  const segmentEventName = eventName;
  const segmentEventProps = {
    jobId: job?.atsJobId,
    origin: document.referrer,
    profession: job?.professionCode,
    specialty: job?.specialtyCode,
    state: job?.state,
    city: job?.city,
    zip: job?.zip,
    shift: job?.shift,
    type: job?.type,
    weeklyPay: job?.salaryMax,
    facilityName: job?.facilityName,
    sourceType: props?.sourceType,
    sourceSubArea: props?.campaignCode,
    eligibilityPercent: eligibilityPercentageCalc(job),
  };
  Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
};

const JobCardHeader = ({
  job,
  chips,
  showContactAction,
  showFavoriteAction,
  allJobs,
  jobDetails,
  similarJobs,
  collaboratedJobs,
  myJobs,
  recommendedJobs,
  recommended,
  isRecommendedJobsOnResult,
  similarOrViewed,
  viewMyJobs,
  className,
  error,
  emailSuccess,
  emailSuccessResponse,
  actionJobId,
  sourceType,
  campaignCode,
  postTheJobApplicationFavoriteEmail,
  onEmailClick,
  onClick,
}: JobCardHeaderProps) => {
  const { t } = useTranslation();

  const renderEmailFeedbackTooltip = () => {
    if (job == null) {
      return null;
    }

    return (
      <ControlledEmailTooltip
        handleEmailClick={() => onEmailClick()}
        error={error}
        success={emailSuccess}
        job={job}
        emailSuccessResponse={emailSuccessResponse}
        actionJobId={actionJobId}
      />
    );
  }


  const renderDefaultEmailTooltip = () => {
    if (job == null) {
      return null;
    }

    return (
      <Tooltip
        title={t("jobs.emailRecruiter.contactRecruiter")!}
        aria-label="Recruiter"
        placement="top"
      >
        <IconButton
          component="button"
          role="button"
          tabIndex={0}
          size="small"
          onKeyDown={onEmailClick}
          onClick={onEmailClick}
        >
          {job.isEmailed ? (
            <MailIcon className="headerIcon recruiterIconFilled label-root" />
          ) : (
            <MailOutlinedIcon className="headerIcon recruiterIcon label-root" />
          )}
        </IconButton>
      </Tooltip>
    );
  }

  const [isFavorite, setIsFavorite] = useState<boolean>(job?.isFavorite ?? false);
  const [canFavorite, setCanFavorite] = useState<boolean>(true);

  const toggleFavorite = () => {
    const jobId = job?.id;

    if (jobId != null) {
      const newValue = !isFavorite;

      setIsFavorite(newValue);
      setCanFavorite(false);
      postTheJobApplicationFavoriteEmail({
        jobId,
        action: "favorite",
        userId: 2,
        request: {
          isActive: newValue,
        },
        jobs: allJobs,
        jobDetails,
        similarJobs,
        collaboratedJobs,
        similarOrViewed,
        recommended,
        viewMyJobs,
        myJobs,
        recommendedJobs,
        isRecommendedJobsOnResult,
      });

      const segmentEventName = t("segment.favoriteJob");

      if (newValue)
        trackSegmentEvent(segmentEventName, {job, sourceType, campaignCode})

      setTimeout(() => {
        setCanFavorite(true);
      }, 1000);
    }
  };

  const getFavoriteTooltip = () => {
    if (canFavorite) {
      return isFavorite ? "Remove from Favorites" : "Add to Favorites";
    }

    return isFavorite ? "Adding..." : "Removing...";
  }

  const [isImageBroken, setIsImageBroken] = useState<boolean>(false);

  if (job == null) {
    return (
      <div className={clsx(styles.main, className, styles.noBackground)}>
        <Skeleton variant="rectangular" width="100%" height={140} />
      </div>
    )
  }

  const image = (isImageBroken || job.imageUri == null) ?
    "https://d308dt1i66cme4.cloudfront.net/content/images/facility/default/default.jpg"
    : `${imageUrl}${job.imageUri}`;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick?.()}
      onClick={() => onClick?.()}
      className={clsx(styles.main, className)}
    >
      <div className={styles.top}>
        <div>
          {chips != null && chips.length > 0 && (
            <div className={styles.chips}>
              {chips.map((chipProps: JobCardChipProps) => (
                <JobCardChip
                  key={uuidv4()}
                  {...chipProps}
                  className={clsx(styles.chip, chipProps.className)}
                />
              ))}
            </div>
          )}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={styles.actions}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {showContactAction && (
            <div className={styles.contact}>
              {(emailSuccess || error) && actionJobId === job.id
                ? renderEmailFeedbackTooltip()
                : renderDefaultEmailTooltip()}
            </div>
          )}

          {showFavoriteAction && (
            <Tooltip title={getFavoriteTooltip()} aria-label="favorites" placement="top">
              <div className={clsx(isFavorite && styles.isFavorite, styles.favorite)}>
                <IconButton disabled={!canFavorite} onClick={toggleFavorite}>
                  {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      <img
        src={image}
        alt={`${job.city}, ${job.state}`}
        className={styles.image}
        onError={() => {
          setIsImageBroken(true);
        }}
      />
    </div>
  );
}

JobCardHeader.defaultProps = {
  job: undefined,
  chips: [],
  showContactAction: true,
  showFavoriteAction: true,
  isRecommendedJobsOnResult: false,
  recommended: false,
  similarOrViewed: "",
  viewMyJobs: false,
  className: "",
  onClick: undefined,
}

const mapStateToProps = (state) => ({
  emailSuccess: state.emailRecruiter.emailSuccess,
  emailSuccessResponse: state.emailRecruiter.emailSuccessResponse,
  error: state.emailRecruiter.error,
  jobFavoriteSuccess: state.applyFavEmail.jobFavoriteSuccess,
  allJobs: state.jobResults.jobs,
  similarJobs: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobs: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobs: state.jobs.recommendedJobs,
  jobDetails: state.jobDetails.jobDetails,
  myJobs: state.myJobs.myJobs,
  filteredJobs: state.myJobs.filteredJobs,
  actionJobId: state.emailRecruiter.actionJobId,
});

const mapDispatchToProps = (dispatch) => ({
  postTheJobApplicationFavoriteEmail: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobCardHeader));
