import { gql } from "@apollo/client";

export const GET_PII_TOKEN_INFORMATION = (fields: string) => gql`
  query GetPIITokens($email: String!) {
    getPIITokens(email: $email) {
      id
      email
      ${fields}
      updated_at
      is_deleted
      is_active
      created_at
      userMessage
      isSuccess
      developerMessage
      code
    }
  }
`;

export const SET_PII_TOKEN_INFORMATION = (fields: string) => gql`
  mutation GeneratePIITokens($email: String!) {
    generatePIITokens(email: $email) {
      id
      email
      ${fields}
      is_active
      is_deleted
      created_at
      updated_at
      isSuccess
      code
      developerMessage
      userMessage
    }
  }
`;

export const GET_PII_TOKEN_VALUE = gql`
  query GetPIITokenValue($tokens: [String!]!) {
    getPIITokenValue(tokens: $tokens) {
      isSuccess
      piiTokens {
        token
        value
      }
    }
  }
`;

export const SET_PII_TOKEN_VALUE = (piiTokenVariables, variables) => gql`
mutation CreatePIITokenValue(${variables}) {
  ${
    piiTokenVariables.ss_numToken !== undefined
  ? "ssn: createPIITokenValue(token: $ss_numToken, value: $ss_numValue) { isSuccess token value }"
      : ""
  }
  ${
    piiTokenVariables.dobToken !== undefined
  ? "dob: createPIITokenValue(token: $dobToken, value: $dobValue) { isSuccess token value }"
      : ""
  }
  ${
    piiTokenVariables.genderToken !== undefined
  ? "gender: createPIITokenValue(token: $genderToken, value: $genderValue) { isSuccess token value }"
      : ""
  }
  ${
    piiTokenVariables.ethnicityToken !== undefined
  ? "ethnicity: createPIITokenValue(token: $ethnicityToken, value: $ethnicityValue) { isSuccess token value }"
      : ""
  }
}
`;

export const UPDATE_PII_TOKEN_VALUE = (piiTokenVariables, variables) => gql`
  mutation UpdatePIITokenValue(${variables}) {
    ${
      piiTokenVariables.ss_numToken !== undefined
  ? "ssn: updatePIITokenValue(token: $ss_numToken, value: $ss_numValue) { isSuccess token value }"
        : ""
    }
    ${
      piiTokenVariables.dobToken !== undefined
  ? "dob: updatePIITokenValue(token: $dobToken, value: $dobValue) { isSuccess token value }"
        : ""
    }
    ${
      piiTokenVariables.genderToken !== undefined
  ? "gender: updatePIITokenValue(token: $genderToken, value: $genderValue) { isSuccess token value }"
        : ""
    }
    ${
      piiTokenVariables.ethnicityToken !== undefined
  ? "ethnicity: updatePIITokenValue(token: $ethnicityToken, value: $ethnicityValue) { isSuccess token value }"
        : ""
    }
  }
`;

export const getAllReferencesQuery = gql`
  query GetAllReferences($email: String!, $isDeleted: Boolean!) {
    getAllReferences(email: $email, isDeleted: $isDeleted) {
      status
      userMessage
      content {
        companyId
        email
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            dateNotified
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            isDeleted
            referenceId
            atsBullhornCandidateId
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const getReference = gql`
  query GetReference(
    $email: String!
    $getReferenceId: String!
    $companyId: Float!
    $isDeleted: Boolean!
  ) {
    getReference(email: $email, id: $getReferenceId, companyId: $companyId, isDeleted: $isDeleted) {
      status
      userMessage
      content {
        companyId
        email
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            responseOne
            responseTwo
            responseThree
            comments
            isDeleted
            declinationReason
            supervisorPhone
            dateNotified
            referenceId
            atsBullhornCandidateId
            contactConsent
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const SET_REFERENCES = gql`
  mutation SetReferences($referencesInput: ReferencesRequest!) {
    setReferences(referencesInput: $referencesInput) {
      status
      userMessage
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            responseOne
            responseTwo
            responseThree
            comments
            isDeleted
            declinationReason
            dateNotified
            referenceId
            atsBullhornCandidateId
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const DELETE_REFERENCES = gql`
  mutation DeleteReferences($referencesInput: ReferencesRequest!) {
    deleteReferences(referencesInput: $referencesInput) {
      status
      userMessage
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            responseOne
            responseTwo
            responseThree
            comments
            declinationReason
            isDeleted
            referenceId
            atsBullhornCandidateId
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const SET_CANDIDATE = gql`
  mutation setCandidate($registration: CandidateRegistrationInput!) {
    setCandidate(registration: $registration) {
      success
      appRedirectionURL
      developerMessage
      code
      userMessage
    }
  }
`;

export const GET_CHECKLIST = gql`
  query SkillsChecklistDetailsFromAble(
    $email: String!
    $companyId: String!
    $applicantId: String!
    $atsChecklistId: String!
  ) {
    skillsChecklistDetailsFromAble(
      email: $email
      companyId: $companyId
      applicantId: $applicantId
      atsChecklistId: $atsChecklistId
    ) {
      status
      statusCode
      content {
        skillsChecklist {
          id
          customerId
          name
          description
          categoryId
          legend
          subscaleLegend
          scaleStart
          scaleEnd
          required
          useSubSkills
          active
          lastUpdated
          expiration
          created
          draft
          completedDate
          complete
          fileBytes
          fileName
          fileAltId
          signature
          groups {
            id
            checklistId
            name
            initial
            scale
            active
            skills {
              id
              groupId
              name
              scale
              subScaleName
              subScale
              active
              value
            }
          }
        }
      }
      developerMessage
      userMessage
      errorMessage
    }
  }
`;

export const PROFESSION_BY_JOB = gql`
  query ProfessionsByJobType($jobType: String, $category: String) {
    professionsByJobType(jobType: $jobType, category: $category) {
      professions {
        id
        name
        code
        companyId
        category
        company {
          code
        }
      }
    }
  }
`;

export const SPECIALTY_BY_PROFESSION = gql`
  query SpecialtiesByProfession($profession: String, $jobType: String, $category: String) {
    specialtiesByProfession(
      profession: $profession
      jobType: $jobType
      category: $category
      primary: true
    ) {
      specialties {
        professionCode
        companyId
        code
        name
        id
        isPrimary
        company {
          code
        }
      }
    }
  }
`;

export const SEND_EMAIL_VERIFICATION = gql`
  mutation sendCandidateEmailVerification($emailVerification: CandidateEmailVerification!) {
    sendCandidateEmailVerification(emailVerification: $emailVerification) {
      code
      developerMessage
      success
      userMessage
    }
  }
`;

export const getJobQuickApply = gql`
  query getJobQuickApply(
    $jobId: Float!
    $email: String!
    $companyId: Float!
    $bullhornId: String!
    $profession: String!
    $specialty: String!
    $includeSsnAndDob: Boolean
  ) {
    getJobQuickApply(
      jobId: $jobId
      email: $email
      companyId: $companyId
      bullhornId: $bullhornId
      profession: $profession
      specialty: $specialty
      includeSsnAndDob: $includeSsnAndDob
    ) {
      status
      statusCode
      content {
        jobId
        candidateId
        quickApply
        eligibilityPercentage
        qualifications {
          status
          eligibility
          ssnAndDob {
            quickApply
          }
          specialtyProfession {
            quickApply
            profession
            specialty
            candidateProfession
            candidateSpecialty
          }
          workHistory {
            quickApply
            workHistories {
              profession
              facilityName
              specialty
              endDate
              duration
            }
          }
          credential {
            quickApply
            credentials {
              id
              name
              category
              status
              certified
              type
              step
              compact
            }
          }
          checkList {
            quickApply
            checkLists {
              id
              name
              expirationDate
              status
            }
          }
          reference {
            quickApply
            references {
              id
              status
              specialty
            }
          }
        }
      }
      userMessage
    }
  }
`;


export const getJobQuickApplyEligibilityGauge = gql`
  query getJobQuickApply(
    $jobId: Float!
    $email: String!
    $companyId: Float!
    $bullhornId: String!
    $profession: String!
    $specialty: String!
    $includeSsnAndDob: Boolean
    $candidateInformation: CandidateInformation
  ) {
    getJobQuickApply(
      jobId: $jobId
      email: $email
      companyId: $companyId
      bullhornId: $bullhornId
      profession: $profession
      specialty: $specialty
      includeSsnAndDob: $includeSsnAndDob
      candidateInformation: $candidateInformation
    ) {
      status
      statusCode
      content {
        jobId
        candidateId
        quickApply
        eligibilityPercentage
        qualifications {
          status
          eligibility
          ssnAndDob {
            quickApply
          }
          specialtyProfession {
            quickApply
            profession
            specialty
            candidateProfession
            candidateSpecialty
          }
          workHistory {
            quickApply
            workHistories {
              profession
              facilityName
              specialty
              endDate
              duration
            }
          }
          credential {
            quickApply
            credentials {
              id
              name
              category
              status
              certified
              type
              step
              compact
            }
          }
          checkList {
            quickApply
            checkLists {
              id
              name
              expirationDate
              status
            }
          }
          reference {
            quickApply
            references {
              id
              status
              specialty
            }
          }
        }
      }
      userMessage
    }
  }
`;

export const GET_MY_TASKS_STATUS = gql`
  query GetMyTaskStatusByApplicantId(
    $applicantId: String!
    $bhCandidateId: Float!
    $companyId: Float!
  ) {
    getMyTaskStatusByApplicantId(
      applicantId: $applicantId
      bhCandidateId: $bhCandidateId
      companyId: $companyId
    ) {
      status
      statusCode
      content {
        meta {
          placement {
            facilityName
            startDate
          }
        }
        forms {
          status
          statusCode
          userMessage
          developerMessage
          errorMessage
          tasks {
            completeCount
            incompleteCount
            complete {
              formId
              name
              isComplete
              link
              workflowId
            }
            incomplete {
              formId
              name
              isComplete
              link
              workflowId
            }
          }
        }
        checklists {
          status
          statusCode
          userMessage
          developerMessage
          errorMessage
          tasks {
            completeCount
            incompleteCount
            complete {
              checklistId
              atsChecklistId
              name
              isComplete
            }
            incomplete {
              checklistId
              atsChecklistId
              name
              isComplete
            }
          }
        }
        uploads {
          status
          statusCode
          userMessage
          developerMessage
          errorMessage
          tasks {
            completeCount
            incompleteCount
            complete {
              id
              name
              isComplete
              status
              certificationFileAttachments {
                id
              }
            }
            incomplete {
              id
              status
              name
              isComplete
              dateExpiration
              dateCompleted
              licenseNumber
              certificationFileAttachments {
                id
                name
                fileSize
                contentType
                isEncrypted
                contentSubType
                dateAdded
                dateLastModified
                directory
                fileExtension
                fileOwner {
                  id
                  firstName
                  lastName
                }
                isExternal
                migrateGUID
                type
              }
            }
          }
        }
      }
      userMessage
      developerMessage
      errorMessage
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendEmailVerification($emailVerification: CandidateEmailVerification!) {
    resendEmailVerification(emailVerification: $emailVerification) {
      code
      developerMessage
      success
      userMessage
    }
  }
`;

export const getCandidateCredentials = gql`
  query GetCandidateCredentials(
    $bhCandidateId: Float!
    $companyId: Float!
    $certificationList: Boolean
  ) {
    getCandidateCredentials(
      bhCandidateId: $bhCandidateId
      companyId: $companyId
      certificationList: $certificationList
    ) {
      status
      statusCode
      userMessage
      developerMessage
      certificationList {
        id
        name
        status
        dateExpiration
        dateCompleted
        licenseNumber
        certification {
          category
        }
        certificationFileAttachments {
          id
        }
      }
    }
  }
`;

/**
 * Gets Candidate Credential File data,
 * Required Variable { "credentialsFileId": number! }
 */
export const getCredentialsFile = gql`
  query GetCredentialsFile($credentialsFileId: Float!) {
    getCredentialsFile(credentialsFileId: $credentialsFileId) {
      isSuccess
      code
      developerMessage
      userMessage
      file {
        name
        contentType
        fileContent
      }
    }
  }
`;

export const UpdateCandidateCredentials = gql`
  mutation UpdateCandidateCredentials(
    $bhCandidateId: Float
    $candidateCertId: Float!
    $companyId: Float!
    $certificationContent: CertificationContentUpdate
    $certificationFileIds: [Float!]
    $certificationFiles: [CertificationFile!]
  ) {
    updateCandidateCredentials(
      bhCandidateId: $bhCandidateId
      candidateCertId: $candidateCertId
      companyId: $companyId
      certificationContent: $certificationContent
      certificationFileIds: $certificationFileIds
      certificationFiles: $certificationFiles
    ) {
      status
      statusCode
      userMessage
      developerMessage
    }
  }
`;

export const SaveCandidateCredentials = gql`
  mutation SaveCandidateCredentials(
    $bhCandidateId: Float
    $companyId: Float!
    $certificationContent: CertificationContent
    $certificationFiles: [CertificationFile!]
  ) {
    saveCandidateCredentials(
      bhCandidateId: $bhCandidateId
      companyId: $companyId
      certificationContent: $certificationContent
      certificationFiles: $certificationFiles
    ) {
      status
      statusCode
      developerMessage
      userMessage
    }
  }
`;

export const GET_COMPLETED_FORM_FILE = gql`
  query GetCompletedFormFile(
    $email: String!
    $companyId: String!
    $applicantId: String!
    $workflowId: String!
    $signRequestId: String!
  ) {
    getCompletedFormFile(
      email: $email
      companyId: $companyId
      applicantId: $applicantId
      workflowId: $workflowId
      signRequestId: $signRequestId
    ) {
      developerMessage
      status
      statusCode
      userMessage
      file {
        contentType
        fileContent
        name
      }
    }
  }
`;

export const GET_COMPLETED_SKILL_CHECKLIST_FILE = gql`
  query GetCompletedSkillchecklistFile(
    $email: String!
    $companyId: String!
    $applicantId: String!
    $applicantChecklistId: String!
  ) {
    getCompletedSkillchecklistFile(
      email: $email
      companyId: $companyId
      applicantId: $applicantId
      applicantChecklistId: $applicantChecklistId
    ) {
      developerMessage
      status
      statusCode
      userMessage
      file {
        contentType
        fileContent
        name
      }
    }
  }
`;

export const getAllPaystubs = gql`
  query GetAllPaystubs($candidateId: String!) {
    getAllPaystubs(candidateId: $candidateId) {
      status
      content {
        id
        candidateId
        payPeriod
        checkDate
        taxableGross
        totalNetPay
        title
        name
        payMethod
        totalHours
        totalPay
        totalTaxAmount
      }
      userMessage
    }
  }
`;

export const getPaystub = gql`
  query GetPaystub($paystubId: String!, $candidateId: String!) {
    getPaystub(id: $paystubId, candidateId: $candidateId) {
      status
      content {
        id
        candidateId
        payPeriod
        checkDate
        taxableGross
        totalNetPay
        title
        name
        payMethod
        totalHours
        totalPay
        totalTaxAmount
        totalDeductionAmount
        totalReimbursementAmount
        earnings {
          id
          paystubId
          client
          date
          type
          hours
          rate
          amount
        }
        deductions {
          id
          paystubId
          description
          deduction
          amount
        }
        reimbursements {
          id
          paystubId
          description
          deduction
          amount
        }
        pto {
          id
          paystubId
          description
          avail
          ytdUsed
        }
        taxes {
          id
          paystubId
          tax
          amount
        }
      }
      userMessage
    }
  }
`;


export const GET_MY_JOBS_ELIGIBILITY_GAUGE = gql`
  query MyJobs($actionFilters: String!, $companyId: Int!, $bhCandidateId: Int!, $includeQuickApplyEligibility: Boolean, $candidateInformation: CandidateInformation) {
    myJobs(actionFilters: $actionFilters, companyId: $companyId, bhCandidateId: $bhCandidateId, includeQuickApplyEligibility: $includeQuickApplyEligibility, candidateInformation: $candidateInformation) {
      embedded {
        aggregations {
          min_salary
          max_salary
        }
        resultOfType
        results {
          id
          atsId
          atsJobId
          assignmentId
          webJobId
          title
          jobStatus
          description
          category
          keywords
          city
          state
          zip
          country
          location
          companyId
          companyName
          facilityId
          facilityName
          numberOfBeds
          numberOfOpen
          numberOfFilled
          latitude
          longitude
          tags
          shift
          professions
          professionCode
          professionDescription
          specialties
          specialtyCode
          specialtyDescription
          submissionDate
          msp
          type
          salaryMin
          salaryMax
          duration
          salaryHourly
          salaryExpenseReimbursementWeekly
          isIvs
          isApplied
          isEmailed
          isFavorite
          isQuickApplied
          hoursPerWeek
          priority
          w2Owner
          metro
          imageUri
          subcontractor
          startDate
          datePosted
          replyToEmail
          schedule
          quickApplyEligibility {
            jobId
            candidateId
            quickApply
            qualifications {
              status
              eligibility
              basicInformation {
                firstname
                lastname
                preferredName
                email
                phone
                socialSecurityNumber
                dateOfBirth
                isSSNAvailable
                stateLicensesHeld
                gender
                ethnicity
                isCurrAddressDiff
                emergencyContact
                emergencyPhone
                relation
                isHcp
                shifts
                interests
                dateAvailable
                referredBy
                requestedRecruiter
                travelDestinations
                preferredWorkEnv
                professionalJourney
                quickApply
                currentAddress {
                  streetAddress
                  apt
                  city
                  state
                  country
                  zip
                }
                permanentAddress {
                  streetAddress
                  apt
                  city
                  state
                  country
                  zip
                }
              }
              checkList {
                quickApply
                checkLists {
                  id
                  name
                  expirationDate
                  status
                  completedDate
                  complete
                }
              }
              credential {
                quickApply
                credentials {
                  id
                  name
                  category
                  status
                  certified
                  type
                  step
                  expirationDate
                  compact
                }
              }
              reference {
                quickApply
                references {
                  id
                  status
                  specialty
                }
              }
              specialtyProfession {
                quickApply
                profession
                specialty
                candidateProfession
                candidateSpecialty
              }
              ssnAndDob {
                quickApply
              }
              workHistory {
                quickApply
                workHistories {
                  profession
                  facilityName
                  specialty
                  startDate
                  endDate
                  isCurrentlyWorking
                  reasonForLeaving
                  duration
                  facilityType
                  emrTechnology
                  employmentType
                  completeness
                }
              }
            }
          }
        }
        tags {
          name
          count
        }
      }
      isSuccess
      code
      developerMessage
      userMessage
    }
  }
`;

export const GET_MY_JOBS = gql`
  query MyJobs($actionFilters: String!, $companyId: Int!, $bhCandidateId: Int!) {
    myJobs(actionFilters: $actionFilters, companyId: $companyId, bhCandidateId: $bhCandidateId) {
      embedded {
        aggregations {
          min_salary
          max_salary
        }
        resultOfType
        results {
          id
          atsId
          atsJobId
          assignmentId
          webJobId
          title
          jobStatus
          description
          category
          keywords
          city
          state
          zip
          country
          location
          companyId
          companyName
          facilityId
          facilityName
          numberOfBeds
          numberOfOpen
          numberOfFilled
          latitude
          longitude
          tags
          shift
          professions
          professionCode
          professionDescription
          specialties
          specialtyCode
          specialtyDescription
          submissionDate
          msp
          type
          salaryMin
          salaryMax
          duration
          salaryHourly
          salaryExpenseReimbursementWeekly
          isIvs
          isApplied
          isEmailed
          isFavorite
          isQuickApplied
          hoursPerWeek
          priority
          w2Owner
          metro
          imageUri
          subcontractor
          startDate
          datePosted
          replyToEmail
          schedule
        }
        tags {
          name
          count
        }
      }
      isSuccess
      code
      developerMessage
      userMessage
    }
  }
`;

// Get Facility Custom Content using atsFacilityId
export const GET_FACILITY_CUSTOM_CONTENT = gql`
  query GetFacilityCustomContent($atsFacilityId: String) {
    getFacilityCustomContent(atsFacilityId: $atsFacilityId) {
      isSuccess
      code
      developerMessage
      userMessage
      results {
        data {
          orgAtsId
          organization
          company
          id
          type
          info
        }
        name
        id
      }
    }
  }
`;

export const GET_ALL_WORK_EXPERIENCE = gql`
  query GetAllWorkExperience($email: String!, $isDeleted: Boolean) {
    getAllWorkExperience(email: $email, isDeleted: $isDeleted) {
      status
      userMessage
      content {
        candidateWorkExperience {
          meta {
            lastVisitedPage
            completedSections {
              basicInfo
              references
              skillsChecklists
              specialtiesAndEducation
              workExperience
            }
          }
          workExperience {
            atsBullhornCandidateId
            chargeExperience
            city
            emrTechnology
            endDate
            facilityName
            facilityType
            floatingPlace
            isCurrentlyWorking
            isTempJob
            isVerified
            meta {
              associations {
                isDeleted
                tenantEntityId
                tenantId
                tenantLastEgressSyncDate
                tenantLastIngressSyncDate
              }
              createdBy
              createdDate
              id
              updatedBy
              updatedDate
            }
            patientRatio
            position
            profession
            reasonForLeaving
            shiftWorked
            specialty
            staffingAgency
            startDate
            supervisor
            teachingFacility
          }
        }
        companyId
        createdBy
        dateCreated
        dateLastUpdated
        email
        id
        isActive
        isDeleted
        updatedBy
      }
    }
  }
`;

export const SET_WORK_EXPERIENCE = gql`
  mutation SetWorkExperience($workExperienceInput: WorkExperienceRequest!) {
    setWorkExperience(workExperienceInput: $workExperienceInput) {
      status
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateWorkExperience {
          workExperience {
            meta {
              id
              createdDate
              createdBy
              updatedDate
              updatedBy
              associations {
                tenantId
                tenantEntityId
                tenantLastIngressSyncDate
                tenantLastEgressSyncDate
                isDeleted
              }
            }
            facilityName
            city
            profession
            specialty
            position
            supervisor
            shiftWorked
            facilityType
            emrTechnology
            patientRatio
            floatingPlace
            teachingFacility
            chargeExperience
            startDate
            endDate
            isCurrentlyWorking
            reasonForLeaving
            staffingAgency
            isTempJob
            isVerified
            atsBullhornCandidateId
          }
          meta {
            lastVisitedPage
            completedSections {
              workExperience
            }
          }
        }
      }
      userMessage
    }
  }
`;

export const UPDATE_WORK_EXP_STATUS = gql`
  mutation UpdateWorkExperienceStatus(
    $updateWorkExperienceStatusInput: UpdateWorkExperienceStatusRequest!
  ) {
    updateWorkExperienceStatus(updateWorkExperienceStatusInput: $updateWorkExperienceStatusInput) {
      status
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateWorkExperience {
          meta {
            lastVisitedPage
            completedSections {
              workExperience
            }
          }
          workExperience {
            meta {
              id
              createdDate
              createdBy
              updatedBy
              updatedDate
              associations {
                tenantId
                tenantEntityId
                tenantLastIngressSyncDate
                tenantLastEgressSyncDate
                isDeleted
              }
            }
            facilityName
            city
            profession
            specialty
            position
            supervisor
            shiftWorked
            facilityType
            emrTechnology
            patientRatio
            floatingPlace
            teachingFacility
            chargeExperience
            startDate
            endDate
            isCurrentlyWorking
            reasonForLeaving
            staffingAgency
            isTempJob
            isVerified
            atsBullhornCandidateId
          }
        }
      }
      userMessage
    }
  }
`;

export const GET_BH_CANDIDATE_EXTERNAL_ID = gql`
  query GetBHCandidateExternalId($email: String!) {
    getBHCandidateExternalId(email: $email) {
      isSuccess
      code
      developerMessage
      userMessage
      bhCandidateId
    }
  }
`;

export const GET_WEATHER_DATA = gql`
  query getWeather($zip: String!) {
    getAvgHighTemperature(zipCode: $zip) {
      city
      state
      zipCode
      Jan21
      Feb21
      Mar21
      Apr21
      May21
      Jun21
      Jul21
      Aug21
      Sep21
      Oct21
      Nov21
      Dec21
    }
    getAvgLowTemperature(zipCode: $zip) {
      city
      state
      zipCode
      Jan21
      Feb21
      Mar21
      Apr21
      May21
      Jun21
      Jul21
      Aug21
      Sep21
      Oct21
      Nov21
      Dec21
    }
    getAvgRainfall(zipCode: $zip) {
      city
      state
      zipCode
      Jan21
      Feb21
      Mar21
      Apr21
      May21
      Jun21
      Jul21
      Aug21
      Sep21
      Oct21
      Nov21
      Dec21
    }
  }
`;

export const GET_PII_TOKENS = gql`
query GetPIITokens($email: String!) {
  getPIITokens(email: $email) {
    id
    email
    dob
    gender
    ethnicity
    ss_num
    updated_at
    is_deleted
    is_active
    created_at
    userMessage
    isSuccess
    developerMessage
    code
  }
}
`;

export const GET_SKILL_CHECKLIST =gql `
query Query {
  getSkillschecklists {
    isSuccess
    code
    developerMessage
    userMessage
    skillschecklists {
      id
      name
    }
  }
}`;


export const GET_MY_JOBS_STATUS = gql`
query MyJobs($actionFilters: String!, $companyId: Int!, $bhCandidateId: Int) {
  myJobs(actionFilters: $actionFilters, companyId: $companyId, bhCandidateId: $bhCandidateId) {
    embedded {
      results {
        jobStatus
      }
    }
  }
}
`;

/**
 * GraphQL query to fetch job details by jobId
 */
export const GET_JOB_DETAILS = gql`
query getJob($jobId: Float!) {
  getJob(jobId: $jobId){
    content {
      city
      id
      atsId
      atsJobId
      webJobId
      title
      jobStatus
      assignmentId
      description
      category
      keywords
      state
      zip
      country
      location
      companyId
      companyName
      facilityId
      facilityName
      numberOfBeds
      numberOfOpen
      numberOfFilled
      latitude
      longitude
      tags
      shift
      professions
      professionCode
      professionDescription
      specialties
      specialtyCode
      specialtyDescription
      msp
      type
      salaryMin
      salaryMax
      duration
      salaryHourly
      salaryExpenseReimbursementWeekly
      isIvs
      isApplied
      isEmailed
      isFavorite
      isQuickApplied
      hoursPerWeek
      priority
      w2Owner
      metro
      imageUri
      subcontractor
      startDate
      endDate
      submissionDate
      salaryMealsDaily
      salaryLodgingDaily
      otRate
      otHours
      regHours
      datePosted
      replyToEmail
      schedule
      isCustomContentEnabled
    }
    developerMessage
    status
    statusCode
    userMessage
  }
}
`;

export const GET_MSP_JOBS = gql`
query GetMSPJobs($specialty: String!, $profession: String!, $companyId: String!, $size: Float) {
  getMSPJobs(specialty: $specialty, profession: $profession, companyId: $companyId, size: $size) {
    isSuccess
    code
    developerMessage
    userMessage
    embedded {
      results {
        id
        atsId
        atsJobId
        webJobId
        title
        jobStatus
        assignmentId
        description
        category
        keywords
        city
        state
        zip
        country
        location
        companyId
        companyName
        facilityId
        facilityName
        numberOfBeds
        numberOfOpen
        numberOfFilled
        latitude
        longitude
        tags
        shift
        professions
        professionCode
        professionDescription
        specialties
        specialtyCode
        specialtyDescription
        msp
        type
        salaryMin
        salaryMax
        duration
        salaryHourly
        salaryExpenseReimbursementWeekly
        isIvs
        isApplied
        isEmailed
        isFavorite
        isQuickApplied
        hoursPerWeek
        priority
        w2Owner
        metro
        imageUri
        subcontractor
        startDate
        endDate
        submissionDate
        salaryMealsDaily
        salaryLodgingDaily
        datePosted
        replyToEmail
        schedule
        isCustomContentEnabled
      }
      tags {
        name
        count
      }
      resultOfType
      aggregations {
        max_salary
        min_salary
        facilities {
          facilityId
          facilityName
        }
      }
    }
    page {
      size
      number
      totalPages
      totalElements
    }
  }
}
`;


export const  SEARCH_JOBS_WITH_VIEWS= gql`
query SearchJobsWithViews($professions: String, $specialties: String, $companyId: Int, $candidateInformation: CandidateInformation, $candidateId: Float, $isProfileComplete: Boolean, $includeQuickApplyEligibility: Boolean) {
  searchJobsWithViews(professions: $professions, specialties: $specialties, companyId: $companyId, candidateInformation: $candidateInformation, candidateId: $candidateId, isProfileComplete: $isProfileComplete, includeQuickApplyEligibility: $includeQuickApplyEligibility) {
    code
    developerMessage
    userMessage
    page {
      size
      number
      totalPages
      totalElements
    }
    embedded {
      results {
        id
        atsId
        atsJobId
        webJobId
        title
        jobStatus
        assignmentId
        description
        category
        keywords
        city
        state
        zip
        country
        location
        companyId
        companyName
        facilityId
        facilityName
        numberOfBeds
        numberOfOpen
        numberOfFilled
        latitude
        longitude
        tags
        shift
        professions
        professionCode
        professionDescription
        specialties
        specialtyCode
        specialtyDescription
        msp
        type
        salaryMin
        salaryMax
        duration
        salaryHourly
        salaryExpenseReimbursementWeekly
        isIvs
        isApplied
        isEmailed
        isFavorite
        isQuickApplied
        hoursPerWeek
        priority
        w2Owner
        metro
        imageUri
        subcontractor
        startDate
        endDate
        submissionDate
        salaryMealsDaily
        salaryLodgingDaily
        datePosted
        replyToEmail
        schedule
        isCustomContentEnabled
        views
        quickApplyEligibility {
          jobId
          candidateId
          quickApply
          eligibilityPercentage
          qualifications {
            status
            eligibility
            specialtyProfession {
              quickApply
              profession
              specialty
              candidateProfession
              candidateSpecialty
            }
            workHistory {
              quickApply
              workHistories {
                profession
                facilityName
                specialty
                startDate
                endDate
                isCurrentlyWorking
                reasonForLeaving
                duration
                facilityType
                emrTechnology
                employmentType
                completeness
              }
            }
            credential {
              quickApply
              credentials {
                id
                name
                category
                status
                certified
                type
                step
                expirationDate
                compact
              }
            }
            checkList {
              quickApply
              checkLists {
                id
                name
                expirationDate
                status
                completedDate
                complete
              }
            }
            reference {
              quickApply
              references {
                id
                status
                specialty
              }
            }
            basicInformation {
              firstname
              lastname
              preferredName
              email
              phone
              socialSecurityNumber
              dateOfBirth
              isSSNAvailable
              stateLicensesHeld
              gender
              ethnicity
              permanentAddress {
                streetAddress
                apt
                city
                state
                country
                zip
              }
              isCurrAddressDiff
              currentAddress {
                streetAddress
                apt
                city
                state
                country
                zip
              }
              emergencyContact
              emergencyPhone
              relation
              isHcp
              shifts
              interests
              dateAvailable
              referredBy
              requestedRecruiter
              travelDestinations
              preferredWorkEnv
              professionalJourney
              quickApply
            }
            ssnAndDob {
              quickApply
            }
          }
        }
      }
      resultOfType
      jobsSource
      tags {
        name
        count
      }
    }
  }
}`

export const GET_RELATED_SPECIALTIES = gql`
query GetRelatedSpecialtiesSearch($companyId: Int, $professionDescriptions: [String!], $specialtyDescriptions: [String!]) {
  getRelatedSpecialtiesSearch(companyId: $companyId, professionDescriptions: $professionDescriptions, specialtyDescriptions: $specialtyDescriptions) {
    statusCode
    content {
      relatedSpecialties {
        name
      }
    }
    userMessage
    developerMessage
  }
}
`

export const GET_COGNITO_USER = gql`
  query GetCognitoUser($email: String!) {
    getCognitoUser(email: $email) {
      userMessage
      developerMessage
      statusCode
      user {
        Username
        Enabled
        UserStatus
        UserAttributes {
          Name
          Value
        }
      }
    }
  }
`

export const GET_USER_AUTH_STATUS = gql`
query GetUserStatusAndAuthEvents($email: String!) {
  getUserStatusAndAuthEvents(email: $email) {
    status
    statusCode
    content {
      userData {
        id
        email
        firstname
        lastname
        username
        phone
        isActive
        isDeleted
        dateCreated
        lastUpdated
        lastLoginDate
      }
      userAuthEventDataList {
        id
        userId
        eventName
        createdBy
        createdAt
      }
    }
    userMessage
    developerMessage
    errorMessage
  }
}
`

export const SET_USER_AUTH_EVENT = gql`
  mutation SaveUserAuthEvent($email: String!, $eventName: UserAuthEventName!, $createdBy: UserAuthCreatedBy!) {
    saveUserAuthEvent(email: $email, eventName: $eventName, createdBy: $createdBy) {
    status
    statusCode
    content {
      userData {
        id
        email
        firstname
        lastname
        username
        phone
        isActive
        isDeleted
        dateCreated
        lastUpdated
        lastLoginDate
      }
      userAuthEventDataList {
        id
        userId
        eventName
        createdBy
        createdAt
      }
    }
    userMessage
    developerMessage
    errorMessage
    }
  }
`;