/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
// @ts-nocheck
import { jobDetailType, MyJobsFilters } from "src/components/common/MainContent/JobResults/common";
import clsx from "clsx";
import config from "src/config";
import getCandidateInformation, { OnboardingInfo } from "src/components/common/CandidateInformation/CandidateInformation";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import { CampaignCode } from "src/constants/campaign_code";
import { detectJobCardClick } from "src/redux/jobDetails/action";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DashboardProps } from "src/components/common/MainContent/Home/index";
import jobsearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import { useHistory } from "react-router-dom";
import { JOBS_DETAILS } from "src/router/routes";
import { useState } from "react";
import JobCardFooter, { JobCardFooterProps } from "./footer/JobCardFooter";
import JobCardContent, { JobCardContentProps } from "./content/JobCardContent";
import JobCardHeader, { JobCardHeaderProps } from "./header/JobCardHeader";
import styles from "./JobCard.module.scss";
import JobCardEmail from "./email/JobCardEmail";
import { random } from "lodash";

export interface JobCardProps {
  job?: jobDetailType;
  headerOptions?: Omit<JobCardHeaderProps, "job">;
  contentOptions?: Omit<JobCardContentProps, "job">;
  footerOptions?: Omit<JobCardFooterProps, "job">;
  campaignCode?: CampaignCode;
  sourceType?: CampaignCode;
  myJobsFilter: MyJobsFilters;
  onboardingInfo: OnboardingInfo;
  atsBullhornCandidateId: string;
  bhId: string | undefined;
  mspJobs: any | undefined;
  jobsSource?: RecommendedJobsSourceTypes;
  companyCode: DashboardProps["companyCode"];
  className?: string;
  detectTheJobCardClick: (obj: {
    isCardClicked: boolean;
    job: jobDetailType;
    isRecommended: boolean | null;
    campaignCode: string;
    companyId: string;
    size: string | undefined;
  }) => void | undefined;
}

const JobCard = ({
  job: jobProp,
  headerOptions,
  contentOptions,
  footerOptions,
  campaignCode,
  myJobsFilter,
  onboardingInfo,
  companyCode,
  atsBullhornCandidateId,
  bhId,
  jobsSource,
  sourceType,
  className,
  detectTheJobCardClick,
}: JobCardProps) => {
  const [job, setJob] = useState<jobDetailType | undefined>(jobProp);
  const history = useHistory();

  const showHeader = headerOptions != null;
  const showContent = contentOptions != null;
  const showFooter = footerOptions != null;

  const modifyJob = (partialJob: Partial<jobDetailType>) => {
    setJob((previousJob) => ({ ...previousJob, ...partialJob }) as jobDetailType);
  };

  const handleGoToClick = () => {
    if (!job) {
      return;
    }

    if (myJobsFilter === "assignments") {
      return;
    }

    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());

    const candidateInformation = getCandidateInformation(onboardingInfo);
    const isRecommendedJobsOnResult = sourceType === "Recommended Jobs" || sourceType === "Match Recommended Jobs";

    const obj = {
      isCardClicked: true,
      job,
      isRecommended: isRecommendedJobsOnResult === true ? true : null,
      campaignCode: campaignCode as string,
      companyId: companyCode?.id ?? "",
      size: config.JOB_COUNT,
      candidateId: atsBullhornCandidateId || bhId,
      candidateInformation,
    }

    detectTheJobCardClick(obj);

    const jobsSourceParam = (jobsSource === RecommendedJobsSourceTypes.NONE || jobsSource === undefined) ? "" : `&jobsSource=${jobsSource}`;
    const queryString = `jobId=${job.id}&companyId=${companyCode?.id
      }&campaignCode=${campaignCode}&isRecommended=${isRecommendedJobsOnResult ? true : null
      }&isApplied=${job.isApplied}&sourceType=${sourceType}${jobsSourceParam}`;

    jobsearchQueryBuilder.setOpenJobDetails(true);

    history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
  }

  const onHeaderClick = () => {
    if (headerOptions?.onClick != null) {
      headerOptions?.onClick();
    } else {
      handleGoToClick();
    }
  }

  const onContentClick = () => {
    if (contentOptions?.onClick != null) {
      contentOptions?.onClick();
    } else {
      handleGoToClick();
    }
  }

  const [isShowingEmail, setIsShowingEmail] = useState<boolean>(false);

  const handleEmailClick = () => {
    if (job == null) {
      return;
    }

    setIsShowingEmail(true);
  }

  const handleEmailDismiss = () => {
    setIsShowingEmail(false);
  }

  if (isShowingEmail && job != null) {
    return (
      <div className={clsx(styles.main, className)}>
        <JobCardEmail job={job} onDismiss={handleEmailDismiss} modifyJob={modifyJob} />
      </div>
    )
  }

  return (
    <div className={clsx(styles.main, className, (!showHeader && !showFooter && styles.onlyContent))}>
      {showHeader && (
        <JobCardHeader
          job={job}
          {...headerOptions}
          sourceType={sourceType}
          campaignCode={campaignCode}
          onClick={onHeaderClick}
          onEmailClick={handleEmailClick}
        />
      )}
      {showContent && (
        <JobCardContent
          job={job}
          {...contentOptions}
          onClick={onContentClick}
          className={clsx(
            contentOptions.className,
            !showHeader && styles.roundedTop,
            !showFooter && styles.roundedBottom,
            (showHeader || showFooter) && styles.shadow,
          )}
        />
      )}
      {showFooter && (
        <JobCardFooter
          job={job}
          {...footerOptions}
          sourceType={sourceType}
          campaignCode={campaignCode}
          modifyJob={modifyJob}
        />
      )}
    </div>
  );
}

JobCard.defaultProps = {
  job: undefined,
  headerOptions: undefined,
  contentOptions: undefined,
  footerOptions: undefined,
  campaignCode: undefined,
  sourceType: undefined,
  jobsSource: RecommendedJobsSourceTypes.NONE,
  className: "",
}

const mapStateToProps = (state) => ({
  myJobsFilter: state.myJobs.filterSelected,
  onboardingInfo: state.onBoarding,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
  mspJobs: state.jobs.mspJobs,
  companyCode: state.company.company,
});

const mapDispatchToProps = (dispatch) => ({
  detectTheJobCardClick: (obj) => dispatch(detectJobCardClick(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobCard));